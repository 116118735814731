
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route,Switch } from "react-router-dom";
import LuckySpin from './LuckySpin/Luckyspin.jsx'

class App extends Component {
    render() {
        return (

            <Router >
                 <Switch>
                      {/* <Header /> */}

                    <Route exact path="/" component={About} />
                    
                    <Route path="/vongquay" component={LuckySpin} />
                    {/* <Route path="/topics" component={Topics} /> */}
                </Switch>
            </Router>
        );
    }
}
function About() {
    return <h2>Chat Maki</h2>;
  }
  function Home() {
    return <h2>Chat Maki</h2>;
  }


ReactDOM.render(
    <App />, document.getElementById('root')

);