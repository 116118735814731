import React, { Component } from 'react';

import { Stage, Layer, Text, Image, Arc, Group, Circle } from 'react-konva';
import "./LuckySpinStyle.css"
import Popup from "reactjs-popup";
import 'url-search-params-polyfill';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();

// class LuckyItem extends Component {
//     render() {
//         return (

//                                 <Arc rotation={startRotation + (index * angleItem)} angle={angleItem} strokeWidth={strokeWidth} fill="red" stroke="white" x={centerX} y={centerY} innerRadius={innberRadius} outerRadius={outerRadius - strokeWidth} />
//                                 // <Text text={item.title}  x={centerX} y={centerY} fill= "white" fontSize="20" />

//         )
//     }
// }


class URLImage extends React.Component {
    state = {
        image: null
    };
    componentDidMount() {
        this.loadImage();
    }
    componentDidUpdate(oldProps) {
        if (oldProps.src !== this.props.src) {
            this.loadImage();
        }
    }
    componentWillUnmount() {
        this.image.removeEventListener('load', this.handleLoad);
    }
    loadImage() {
        // save to "this" to remove "load" handler on unmount
        this.image = new window.Image();
        this.image.src = this.props.src;
        this.image.addEventListener('load', this.handleLoad);
    }
    handleLoad = () => {
        // after setState react-konva will update canvas and redraw the layer
        // because "image" property is changed
        this.setState({
            image: this.image
        });
        // if you keep same image object during source updates
        // you will have to update layer manually:
        // this.imageNode.getLayer().batchDraw();
    };



    render() {


        return (
            <Image
                x={this.props.x}
                y={this.props.y}
                rotation={this.props.rotation}
                image={this.state.image}
                ref={node => {
                    this.imageNode = node;
                }}
            />
        );
    }
}

// var urlImageSpin = new Image();
// urlImageSpin.src = "/spin.png";



class LuckySpin extends Component {
    constructor(props) {
        super(props);
        let arrItem = [];
        this.state = { items: arrItem, bonus: "", open: false, imageBg: "", fontSize: 16, fontColor: "#ffffff" };

        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.session = decodeURIComponent(this.getAllUrlParams(window.location.search).session)
        // console.log(this.session)
        // const encode = decodeURIComponent(this.session);
        // console.log(encode)
        this.getJsonData(1,{ session: this.session, type: 1 }, this.initData)

    }

    session
    uid = 0;
    rotation = 0;
    totalPercent = 0;
    canvasSizeWidth = 300;
    canvasSizeHeight = 300;
    centerX = (this.canvasSizeWidth / 2);
    centerY = (this.canvasSizeHeight / 2);
    outerRadius = 150;
    innerRadius = 42;
    strokeWidth = 2;
    startRotation = -90;
    imageSpin = { width: 59, height: 41 }
    imageArrow = { width: 29, height: 41 }
    isSpin = false;

    angleItem = 0;

    countSpin;





    getBonus() {

        var number = 360 - this.rotation % 360;

        for (var i = this.state.items.length - 1; i >= 0; i--) {
            var currentAngel = i * this.angleItem;
            if (number >= currentAngel) {
                this.setState({ bonus:  (this.state.items[i].title), open: true })
                if(this.uid!==-1)
                this.getJsonData(2,{ session: this.session, type: 2,result: ""+this.state.items[i].bonus}, this.initData)
                break;
            }
        }
    }
    randomIntFromInterval(min, max) // min and max included
    {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
    lastAngle = 0;

    handleClick = () => {

        if (!this.isSpin) {

            if (this.uid === -1 || this.countSpin > 0) {
                this.countSpin--
                this.playSound()
                var randomItem = this.randomIntFromInterval(1, this.totalPercent);
                this.isSpin = true;

                var tmp = 0;
                var min, max
                for (var i = 0; i < this.state.items.length; i++) {
                    if (this.state.items[i].percent !== 0) {
                        if (randomItem <= this.state.items[i].percent + tmp) {
                            var currentAngel = i * this.angleItem;
                            min = currentAngel
                            max = min + this.angleItem
                            // console.log("Quay duoc " + this.state.items[i].title)
                            break
                        } else {
                            tmp += this.state.items[i].percent
                        }
                    }
                }

                var RandomNumber = this.randomIntFromInterval(min + this.strokeWidth, max - this.strokeWidth)

                RandomNumber = 360 - RandomNumber


                this.setState({ bonus: "" })
                this.rotation += 3600 + RandomNumber - this.lastAngle;
                this.lastAngle = RandomNumber
                this.rect.to({
                    rotation: this.rotation,
                    duration: 2,
                    onFinish: () => {
                        this.rotation += 720;

                        this.rect.to({
                            rotation: this.rotation,
                            duration: 1,
                            onFinish: () => {
                                this.rotation += 360;

                                this.rect.to({
                                    rotation: this.rotation,
                                    duration: 1,
                                    onFinish: () => {
                                        this.getBonus()
                                        this.isSpin = false;
                                    }
                                });
                            }
                        });
                    }
                });
            }else{
                this.setState({ bonus: "Bạn đã hết lượt quay!", open: true })
            }
        }
    };

    openModal() {
        this.setState({ open: true })
    }
    closeModal() {
        this.setState({ open: false })
    }

   

    getJsonData(type,opts, initData) {
        // console.log('Posting request to GitHub API...' + JSON.stringify(opts));
        fetch('https://udon.chatmaki.com/g/fortune', {
            method: 'POST',
            body: JSON.stringify(opts)
        }).then(function (response) {
            // console.log("aaa " + (response))
            return response.json();
        }).then(function (data) {
            // console.log('Created Gist:', data.data);
            if(type===1)
                initData(data.data)

        });
    }

    initData = (data) => {
        let arrItem = [];
        for (var i = 0; i < data.fields.length; i++) {
            let field = data.fields[i]
            arrItem.push({ id: i, title: "" + field.name, bonus: field.code, color: field.color, percent: parseInt(field.percent,10)});

        }
        // this.playSound()
        // // }
        // console.log(data)
        this.setState({ items: arrItem })
        this.setState({ imageBg: data.image })
        this.uid = data.uid
        this.countSpin = data.count
        // this.state = { items: arrItem, bonus: "", open: false, imageBg: "", fontSize: 20, fontColor: "#ffffff" };

        this.angleItem = 360 / this.state.items.length;
        // console.log(this.angleItem)
        for (var j = 0; j < arrItem.length; j++) {
            this.totalPercent +=arrItem[j].percent;
        }
        // console.log(this.totalPercent)
        this.setState({ items: arrItem })
    }

    playSound = () => {
        var audio = document.getElementById("audio");
        audio.play();
    }

    getAllUrlParams(url) {

        // get query string from url (optional) or window
        var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

        // we'll store the parameters here
        var obj = {};

        // if query string exists
        if (queryString) {

            // stuff after # is not part of query string, so get rid of it
            queryString = queryString.split('#')[0];

            // split our query string into its component parts
            var arr = queryString.split('&');

            for (var i = 0; i < arr.length; i++) {
                // separate the keys and the values
                var a = arr[i].split('=');

                // set parameter name and value (use 'true' if empty)
                var paramName = a[0];
                var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

                // (optional) keep case consistent
                paramName = paramName.toLowerCase();
                if (typeof paramValue === 'string') paramValue = paramValue;

                // if the paramName ends with square brackets, e.g. colors[] or colors[2]
                if (paramName.match(/\[(\d+)?\]$/)) {

                    // create key if it doesn't exist
                    var key = paramName.replace(/\[(\d+)?\]/, '');
                    if (!obj[key]) obj[key] = [];

                    // if it's an indexed array e.g. colors[2]
                    if (paramName.match(/\[\d+\]$/)) {
                        // get the index value and add the entry at the appropriate position
                        var index = /\[(\d+)\]/.exec(paramName)[1];
                        obj[key][index] = paramValue;
                    } else {
                        // otherwise add the value to the end of the array
                        obj[key].push(paramValue);
                    }
                } else {
                    // we're dealing with a string
                    if (!obj[paramName]) {
                        // if it doesn't exist, create property
                        obj[paramName] = paramValue;
                    } else if (obj[paramName] && typeof obj[paramName] === 'string') {
                        // if property does exist and it's a string, convert it to an array
                        obj[paramName] = [obj[paramName]];
                        obj[paramName].push(paramValue);
                    } else {
                        // otherwise add the property
                        obj[paramName].push(paramValue);
                    }
                }
            }
        }

        return obj;
    }


    render() {

        // console.log("a")
        return (

            <div>
                <Popup
                    open={this.state.open}
                    closeOnDocumentClick={true}
                    onClose={this.closeModal}
                    style={{width:'none'}}
                >
                    <div style={{ fontSize: 18, textAlign: "center"}} >
                        {this.state.bonus}
                    </div>
                </Popup>
                <div style={{ position: 'fixed', left: "calc(50% - 134.5px)", top: 70 }}><img src="/luckyspin/vongquay.png" /></div>
                <div style={{ marginTop: 150 }}>

                    <Stage width={this.canvasSizeWidth} height={this.canvasSizeHeight} style={{ margin: "auto", width: this.canvasSizeWidth }}  >
                        <Layer  >
                            {/* <Rect x={0} y={0} width={window.innerWidth} height={window.innerHeight} fill="green" /> */}
                            <Group x={this.centerX} y={this.centerY}  >
                                <Group rotation={this.rotation} ref={node => { this.rect = node; }} >
                                    <Group rotation={this.startRotation} >
                                        {
                                            this.state.items.map((item, index) => (

                                                <Group key={index} rotation={(index * this.angleItem)}>
                                                    <Arc angle={this.angleItem} strokeWidth={this.strokeWidth} fill={item.color} stroke="white" innerRadius={this.innerRadius} outerRadius={this.outerRadius - this.strokeWidth} />
                                                    
                                                    {this.state.imageBg !=="" &&<Text text={item.title} x={this.innerRadius * Math.cos((Math.PI / 180) * this.angleItem / 2) + this.state.fontSize / 2} y={this.innerRadius * Math.sin((Math.PI / 180) * this.angleItem / 2)} rotation={this.angleItem / 2} fill={this.state.fontColor} fontSize={this.state.fontSize} align="center" width={this.outerRadius - this.innerRadius}/>}


                                                </Group>

                                            ))
                                        }
                                    </Group>
                                    {this.state.imageBg !== "" && <URLImage src={this.state.imageBg} x={this.centerX - 300} y={this.centerY - 300} />}

                                </Group>
                            </Group>
                            <Circle radius={this.innerRadius - this.strokeWidth / 2} fill="#e0e9f1" x={this.centerX} y={this.centerY} />
                            <URLImage src={"/luckyspin/spin.png"} x={this.centerX - this.imageSpin.width / 2} y={this.centerY - this.imageSpin.height / 2} />
                            {/* <SpinImage/> */}

                            <Circle radius={this.innerRadius} x={this.centerX} y={this.centerY} onClick={this.handleClick}  onTouchStart={this.handleClick} />
                        </Layer>

                    </Stage>
                    <div style={{ position: 'fixed', left: "calc(50% - 14.5px)", top: 130 }}><img src="/luckyspin/pin.png" /> <audio id="audio" src="/luckyspin/spinsound.mp3" ></audio></div>
                </div>
            </div>
        )
    }
}

export default LuckySpin;

